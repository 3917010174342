import React, { useState } from 'react';
import './App.css';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [show, setShow] = useState(false);
  const [playerName, setPlayerName] = useState('');
  const [players, setPlayers] = useState({});
  const [playersList, setPlayersList] = useState([]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleAddPlayer = () => {
    if (playerName.trim() && !players.hasOwnProperty(playerName)) {
      setPlayers({ ...players, [playerName]: 0 });
    }
    setPlayerName('');
    handleClose();
  };

  const handleAddRow = () => {
    setPlayersList([...playersList, { ...players }]);
  };


  const handleInputChange = (rowIndex, playerName, value) => {
    const updatedPlayersList = playersList.map((row, i) => {
      if (i === rowIndex) {
        return { ...row, [playerName]: Number(value) };
      }
      return row;
    });
    setPlayersList(updatedPlayersList);
  };

  const calculateTotal = (playerName) => {
    return playersList.reduce((total, row) => total + (row[playerName] || 0), 0);
  };

  return (
    <div className="App">
      <Button variant="primary" onClick={handleShow}>
        Add Player
      </Button>
      <Button variant="secondary" onClick={handleAddRow} style={{ marginLeft: '10px' }}>
        Add Row
      </Button>
      <hr/>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Player</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formPlayerName">
              <Form.Label>Player Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter player name"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddPlayer}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <h4>Players: {Object.keys(players).length ?? 0}</h4>
      {playersList.length > 0 && (
        <div className="table-container">
          <table className="table-bordered">
            <thead className="sticky-header">
              <tr>
                {Object.keys(players).map((playerName) => (
                  <th key={playerName} style={{ width: '1%' }}>{playerName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {playersList.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.keys(players).map((playerName) => (
                    <td key={playerName}>
                      <input
                        type="number"
                        value={row[playerName] || ''}
                        placeholder="0"
                        onChange={(e) =>
                          handleInputChange(rowIndex, playerName, e.target.value)
                        }
                        style={{ width: '100%' }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot className="sticky-footer">
              <tr>
                {Object.keys(players).map((playerName) => (
                  <td key={playerName}>{calculateTotal(playerName)}</td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      )}
      <br/>
    </div>
  );
}

export default App;
